import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';


// import Home from './Pages/Home/Home';
import Layout from './Layout/Layout';

const Home = React.lazy(() => import('./Pages/Home/Home'))
const Science = React.lazy(() => import('./Pages/Science/Science'))
const TmFlowHardware = React.lazy(() => import('./Pages/TmFlowHardware/TmFlowHardware'))
const Partners = React.lazy(() => import('./Pages/Partners/Partners'))
const DirectResponseMarketing = React.lazy(() => import('./Pages/DirectResponseMarketing/DirectResponseMarketing'))
const PeceTmFlowSystemMarker = React.lazy(() => import('./Pages/PeceTmFlowSystemMarker/PeceTmFlowSystemMarker'))
const DoctorRecommend = React.lazy(() => import('./Pages/DoctorRecommend/DoctorRecommend'))
const ContactUs = React.lazy(() => import('./Pages/ContactUs/ContactUs'))

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={< Layout />}>
            <Route index element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "}
            <Route path="home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "}
            <Route path="the-science" element={<Suspense fallback={<></>}><Science /></Suspense>} />{" "}
            <Route path="tm-flow-hardware" element={<Suspense fallback={<></>}><TmFlowHardware /></Suspense>} />{" "}
            <Route path="partners" element={<Suspense fallback={<></>}><Partners /></Suspense>} />{" "}
            <Route path="direct-response-marketing" element={<Suspense fallback={<></>}><DirectResponseMarketing /></Suspense>} />{" "}
            <Route path="pece-tm-flow-system-marker" element={<Suspense fallback={<></>}><PeceTmFlowSystemMarker /></Suspense>} />{" "}
            <Route path="doctor-recommend" element={<Suspense fallback={<></>}><DoctorRecommend /></Suspense>} />{" "}
            <Route path="contactus" element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />{" "}
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);