import React, { useEffect, useState } from "react";
import { Link, Navigate, NavLink, Outlet, useLocation } from 'react-router-dom'

import "../Header/Header.css"

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [homeActive,setHomeActive] = useState(false)


  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const location = useLocation();

  useEffect(()=>{
    if(location.pathname=='/') setHomeActive(true);
    else setHomeActive(false);
  },[location])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl2(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    // handleTogglemenu();
  };


  const [isActive, setActive] = useState("false");
  const handleTogglemenu = () => {
    setActive(!isActive);
  };


  return (
    <>
      <div className="vpheaderwrapper mat-card">
        <span className="mainwrappercontent">
          <span className="homemain">
            <span className="asmainwrapper">
              <span className="pagelogowrapper fxFlex" >
                <a><img
                  src="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/logo.webp" alt="logo"
                  className="landinglogoblock1" /></a>
              </span>
              <div className="topcontact mat-card-content">
                <p>
                  <span className="headcommon"><span className="icon"><img
                    src="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/icon-location.webp"
                    alt="location" /></span>
                    18 E Main Street Rexburg, ID&nbsp;83440</span>
                  <span className="headcommonlast"><span className="headcommon"><span className="icon"><img
                    src="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/icon-phonecall.webp"
                    alt="phone call" /></span>
                    <a href="tel:+208-999-3394" target="_top">208-999-3394</a></span>
                    <span className="headcommon"><span className="icon"><img
                      src="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/icon-email.webp"
                      alt="email" /></span>
                      <a href="mailto:support@medigrade.pro " target="_top">support@medigrade.pro
                      </a></span></span>
                </p>
              </div>
            </span>
            <div className="mat-toolbar">
              <div className='mat-toolbar-row'>
                <span className="menumaincls">
                  <span className="menutitle">menu</span>

                  <span className="topmenuiconlogo">
                    <button onClick={handleTogglemenu} >
                      <MenuIcon />
                    </button>
                    <span className="menu-spacer"></span>
                  </span>
                </span>

                <div className={`top_navbar ${isActive ? "" : "addcss"}`}>

                  <span className={"smlmenudiv "}>


                    <div className="cmmn_wdth_nav">

                    <ul className="menuwrapper">

                      <li> <NavLink exact='true' to={"/home"} className={homeActive? 'active':''}  onClick={() => { handleTogglemenu() }}><span>Home</span></NavLink> </li>

                      <li><Button
                    
                        id="menusub1"
                        aria-controls={open ? 'menusub1' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                      >
                        TM-Flow Medical Device<ArrowDropDownIcon />
                      </Button>
                        <Menu
                        
                          id="menusub1"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={handleClose} > <NavLink exact='true' to={"/the-science"}  onClick={() => { handleTogglemenu() }} ><span>The Science</span></NavLink> </MenuItem>
                          <MenuItem onClick={handleClose}> <NavLink exact='true' to={"/tm-flow-hardware"}  onClick={() => { handleTogglemenu() }} ><span>TM-Flow Device</span></NavLink> </MenuItem>
                          <MenuItem onClick={handleClose}> <NavLink exact='true' to={"/pece-tm-flow-system-marker"}  onClick={() => { handleTogglemenu() }} ><span>TM-Flow System Markers</span></NavLink> </MenuItem>
                          <MenuItem onClick={handleClose}> <NavLink exact='true' to={"/doctor-recommend"}  onClick={() => { handleTogglemenu() }} ><span>Doctor Recommend</span></NavLink> </MenuItem>
                        </Menu>
                      </li>

                      <li> 
                      <a href="https://peceplatformtmflow.com" target="_blank"><span>PECE TM-Flow Series</span></a>
                         </li>




                      <li><Button
                        id="menusub2"
                        aria-controls={open2 ? 'menusub2' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleClick2}
                      >
                        PECE Marketing Programs <ArrowDropDownIcon />
                      </Button>
                        <Menu
                          id="menusub2"
                          anchorEl={anchorEl2}
                          open={open2}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={handleClose}>
                          <NavLink exact='true' to={"/direct-response-marketing"}  onClick={() => { handleTogglemenu() }} ><span> Direct Response Marketing System</span></NavLink>
                            <ArrowDropDownIcon /> </MenuItem>
                        </Menu>
                      </li>

                      <li> <NavLink exact='true' to={"/partners"}  onClick={() => { handleTogglemenu() }} ><span> Partners</span></NavLink> </li>
                      <li> <NavLink exact='true' to={"/contactus"}  onClick={() => { handleTogglemenu() }}><span> Contact us </span></NavLink> </li>



                    </ul>
                     
                    </div>

                  </span>

                </div>

              </div>
            </div>
          </span>
        </span>
      </div>

    </>



  )
}
