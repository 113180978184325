import React, { useEffect, useState } from "react";
import { Link, Navigate, NavLink, Outlet, useLocation } from 'react-router-dom'
import "../Footer/Footer.css"


import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

 

export default function Footer() {

  const [homeActive,setHomeActive] = useState(false)

  const location = useLocation();

  useEffect(()=>{
    if(location.pathname=='/') setHomeActive(true);
    else setHomeActive(false);
  },[location])


  const [footeranchorEl, setFooterAnchorEl] = React.useState(null);
  const [footeranchorEl2, setFooterAnchorEl2] = React.useState(null);

  const footeropen = Boolean(footeranchorEl);
  const footeropen2 = Boolean(footeranchorEl2);


  const footerhandleClick = (event) => {
    setFooterAnchorEl(event.currentTarget);
  };

  const footerhandleClick2 = (event) => {
    setFooterAnchorEl2(event.currentTarget);
  };

  const footerhandleClose = () => {
    setFooterAnchorEl(null);
    setFooterAnchorEl2(null);
    
  };



  return (
   <>
  <div  className="footer_section mat-card">
  <div    className="footer_content mat-card-content">
    <span  className="asmainwrapper">
      <a>
        <span  className="scroll-to-top"></span>
        
      </a>
      <div    className="countDiv mat-card-content">
        <div    className="countDiv__column mat-card-content">
          <div    className="countDiv__column--col2 ftgenral mat-card-content">
            <span  className="pagelogowrapper">
              <a ><img
                  src="https://all-frontend-assets.s3.amazonaws.com/medigrade/assets/images/logo-withoutbg-ft.webp"
                  alt="logo withOutBg"  className="logo" /></a>
            </span>
          </div>
          <div    className="countDiv__column--col7 navlinks ftgenral mat-card-content">
            <div    className="mat-list">
              <h2>Quick Links</h2>

              <span  className="navlinkscolsft">
                <span  className="navlinkscols navlinkscolsleft">

                 
                <NavLink exact='true' to={"/home"} className={'foot_active'} ><span>Home</span></NavLink>
                 <Button
                    className="btn"
                    id="footermenusub1"
                    aria-controls={footeropen ? 'footermenusub1' : undefined}
                    aria-haspopup="true"
                    aria-expanded={footeropen ? 'true' : undefined}
                    onClick={footerhandleClick}
                  >
                    TM-Flow Medical Device<ArrowDropDownIcon />
                  </Button>
                    <Menu
                    
                      id="footermenusub1"
                      anchorEl={footeranchorEl}
                      open={footeropen}
                      onClose={footerhandleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={footerhandleClose} > <NavLink exact='true' to={"/the-science"} ><span>The Science</span></NavLink> </MenuItem>
                      <MenuItem onClick={footerhandleClose}> <NavLink exact='true' to={"/tm-flow-hardware"} ><span>TM-Flow Device</span></NavLink> </MenuItem>
                      <MenuItem onClick={footerhandleClose}> <NavLink exact='true' to={"/pece-tm-flow-system-marker"} ><span>TM-Flow System Markers</span></NavLink> </MenuItem>
                      <MenuItem onClick={footerhandleClose}> <NavLink exact='true' to={"/doctor-recommend"} ><span>Doctor Recommend</span></NavLink> </MenuItem>
                    </Menu>
                   
                </span>


                <span  className="navlinkscols navlinkscolsleft">   

                <a className="link" href="https://peceplatformtmflow.com" target="_blank"><span>PECE TM-Flow Series</span></a>

                   <Button className="btn"
                        id="footermenusub2"
                        aria-controls={footeropen2 ? 'footermenusub2' : undefined}
                        aria-haspopup="true"
                        aria-expanded={footeropen2 ? 'true' : undefined}
                        onClick={footerhandleClick2}
                      >
                        PECE Marketing Programs <ArrowDropDownIcon />
                      </Button>
                        <Menu
                          id="footermenusub2"
                          anchorEl={footeranchorEl2}
                          open={footeropen2}
                          onClose={footerhandleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={footerhandleClose}>
                          <NavLink exact='true' className="foot_nav" to={"/direct-response-marketing"} ><span> Direct Response Marketing System</span></NavLink>
                            <ArrowDropDownIcon /> </MenuItem>
                        </Menu>
                </span>
                

                <span  className="navlinkscols navlinkscolsleft cntct_us">
                <NavLink exact='true' to={"/partners"} ><span> Partners</span></NavLink>  
                       <NavLink exact='true' to={"/contactus"} ><span> Contact us </span></NavLink> 
                  
                </span>                
              </span>
            </div  >
          </div>
          <div    className="countDiv__column--col3 ftgenral mat-card-content">
            <h2>Our Address</h2>
            <div    className="contactinfo mat-card-content">
              <div    className="location mat-card-content">
                18 E Main Street Rexburg, ID&nbsp;83440
              </div>
              <div    className="call mat-card-content">
                Fax :<a href="tel:208-999-3394" target="_top">208-999-3394</a>
              </div>
              <div    className="message mat-card-content">
                Email :<a href="mailto:support@medigrade.pro" target="_top">support@medigrade.pro</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
  <div    className="countDiv__column--col12 mat-card-content">
    <p  className="copyright">
      <span  className="asmainwrapper">
        <span  className="tc"><a >Terms & Conditions</a>
          <a >Privacy Policy</a></span>
        Copyright © 2022 medigrade. All Rights Reserved
      </span>
    </p>
  </div>
</div>
   </>
  )
}
