import moment from "moment";
import store from "../store";

////////////////////////////////////// Time Converter Helper //////////////////////////////////
export const timeConverter = (
  time = new Date(),
  format = "MM/DD/YYYY",
  ago = false
) => {
  if (ago === true) {
    return moment.unix(time / 1000).fromNow();
  } else {
    return moment.unix(time / 1000).format(format);
  }
};

export const routeHelper = async ({
  path,
  func,
  state,
  navigate,
  body = {},
  reload = false,
}) => {
  console.log("PATH", path, state, typeof state, func, body);
  if (state.length === 0 || reload || typeof state === "object") {
    await store.dispatch(func(body));
    console.log("Awited");
    navigate(path);
  } else {
    navigate(path);
  }
};
////////////////////// random Id Generator //////////////////////
export const randomId = () => {
  // console.log("Random Id", Math.random().toString(36).slice(2));
  return Math.random().toString(36).slice(2);
};


// //////////////////////// Create Async Thunk Helper /////////////////////////////
export const createAsyncThunkHelper = async ({
  BASE_URL,
  endPoint,
  reqBody,
  restBodyAttachment,
  restResponseAttachment,
  bodyWrapper,
}) => {
  console.log(
    "From Craete Async Thunk Helper",
    BASE_URL,
    endPoint,
    reqBody,
    restBodyAttachment,
    restResponseAttachment,
    bodyWrapper
  );
  let newReqBody = { ...reqBody };

  if (
    typeof restBodyAttachment === "object" &&
    restBodyAttachment !== null &&
    Object.keys(restBodyAttachment).length > 0
  ) {
    for (let key of Object.keys(restBodyAttachment)) {
      newReqBody[key] = restBodyAttachment[key];
    }
  }

  /////////////////// for Body Wrapper ////////////////////
  if (bodyWrapper !== "" && bodyWrapper) {
    console.log(newReqBody);
    newReqBody = {
      [bodyWrapper]: newReqBody,
    };
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(newReqBody),
  };
  const response = await fetch(
    `${BASE_URL}${endPoint !== "" && endPoint !== undefined ? `${endPoint}` : ""
    }`,
    requestOptions
  );
  const result = await response.json();

  if (
    typeof restResponseAttachment === "object" &&
    restResponseAttachment !== null &&
    Object.keys(restResponseAttachment).length > 0
  ) {
    for (let key of Object.keys(restResponseAttachment)) {
      result[key] = restResponseAttachment[key];
    }
  }

  return result;
};

//////////////////////////////////// Create Meta ///////////////////////////////////
export const createMeta = ({ title }) => {
  console.log("title", title)
  document.title = title;
};

/////////////////////////////////// Check if there is already an api call pending ////////////////////////////
let requestCounter = [];
let activeRequest = false;

export const requestSequencer = ({ dispatch, func, args }) => {
  if (requestCounter.length === 0) {
    startInterval();
  }
  requestCounter.push({ dispatch, func, args });

}

const handleRequest = ({ requestCounter }) => {
  activeRequest = true;
  const { dispatch, func, args } = requestCounter.shift()
  dispatch(func(args)).then(() => {
    activeRequest = false;
  })
}

const startInterval = () => {
  const interval = setInterval(() => {
    console.log('Interval running')
    if (requestCounter.length > 0 && activeRequest === false) {
      handleRequest({ requestCounter });
    }
    if (requestCounter.length === 0 && activeRequest === false) {
      clearInterval(interval);
    }
  }, 50)
}




